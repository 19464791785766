<template>
  <v-row class="ma-0">
    <v-col>
      <v-card max-width="580" class="mx-auto text-center px-16 py-13">
        <v-img class="mx-auto" max-width="162" src="@/assets/img/msg.svg"></v-img>
        <div class="text-h5 font-weight-bold mt-10">Your Email Address is not confirmed</div>
        <p class="opasity--text mt-3">
          Check your {{ email }} inbox and click on the “Confirm my email” link to confirm your email address.
        </p>
        <!-- <v-btn @click="again" width="300" height="48" color="secondary">Sent confirmation link again</v-btn> -->
        <v-btn to="/sign-up" text class="opasity--text mt-5"><v-icon left>mdi-arrow-left</v-icon> Back to Sign In</v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import jwt_decode from 'jwt-decode';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      error: null,
    };
  },
  computed: {
    email() {
      return jwt_decode(this.$route.query.t).ead;
    },
  },
  methods: {
    notifi() {
      if (this.error == 'confirmation__too_often') {
        this.$notify({
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'email__already_confirmed') {
        this.$notify({
          message: 'Email is already confirmed',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'account__deactivated') {
        this.$notify({
          message: 'Sorry, but your account has been deactivated',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error == 'account__suspended') {
        this.$notify({
          message: 'Sorry, but your account has been suspended',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async again() {
      sessionStorage.setItem('jwt_token', this.$route.query.t);
      await this.$store
        .dispatch('sendEmail')
        .then(() => {
          this.$router.push('/re-sent');
        })
        .catch((e) => {
          this.error = e.response.data.error[0];
          this.notifi();
        });
    },
  },
};
</script>
